import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import Toolbar, { ToolGroup, ToolItem } from '../ToolBar';
import LockUnlockAllCapacity from './LockUnlockAllCapacity';
import EnableDisablePAXDistribution from './EnableDisablePAXDistribution';
import TimezoneSwitcher from './TimezoneSwitcher';
import Translate from '../System/Translations';
import { TranslateContext } from '../System/Translations';
import UserCan from '../../auth/UserCan';
import BlockSurveyMails from '../BlockSurveyMails';
import AutoModeTrigger from '../AutoModeTrigger';
import ShowHideICsSwitcher from './ShowHideICsSwitcher';

export default function TimelineToolbar(props) {
    const {
        rideUuid,
        rideIsDelayProcessEnabled,
        withBoosterBuses,
        pax2busDistributionEnabled,
    } = props;
    const translate = useContext(TranslateContext);
    const { pathname } = useLocation();

    return (
        <div className="timeline-toolbar-wrapper timeline-toolbar-wrapper--absolute">
            <Toolbar className={translate.language}>
                <ToolGroup>
                    <ToolItem>
                        <Translate>
                            {(translate) => (
                                <TimezoneSwitcher
                                    hint={translate(
                                        'toolbar.timezone_switcher.hint'
                                    )}
                                />
                            )}
                        </Translate>
                    </ToolItem>
                </ToolGroup>

                <ToolGroup>
                    {!pathname.includes('-old') && (
                        <ToolItem>
                            <Translate>
                                {(translate) => (
                                    <ShowHideICsSwitcher
                                        translate={translate}
                                        hint={translate(
                                            'toolbar.timezone_switcher.hint'
                                        )}
                                    />
                                )}
                            </Translate>
                        </ToolItem>
                    )}

                    <UserCan
                        perform="settings_panel"
                        yes={() => (
                            <React.Fragment>
                                <UserCan
                                    perform="lock_all_seats"
                                    yes={() => (
                                        <ToolItem>
                                            <LockUnlockAllCapacity
                                                rideUuid={rideUuid}
                                            />
                                        </ToolItem>
                                    )}
                                />
                                <UserCan
                                    perform="enable_PAX_distribution"
                                    yes={() =>
                                        withBoosterBuses && (
                                            <ToolItem>
                                                <EnableDisablePAXDistribution
                                                    rideUuid={rideUuid}
                                                    pax2busDistributionEnabled={
                                                        pax2busDistributionEnabled
                                                    }
                                                />
                                            </ToolItem>
                                        )
                                    }
                                />
                                <ToolItem>
                                    <AutoModeTrigger
                                        enabled={rideIsDelayProcessEnabled}
                                        rideUuid={rideUuid}
                                    />
                                </ToolItem>
                                <ToolItem>
                                    <BlockSurveyMails rideUuid={rideUuid} />
                                </ToolItem>
                            </React.Fragment>
                        )}
                    />
                </ToolGroup>
            </Toolbar>
        </div>
    );
}

TimelineToolbar.propTypes = {
    rideId: PropTypes.number,
    rideUuid: PropTypes.string,
    rideIsDelayProcessEnabled: PropTypes.bool,
    withBoosterBuses: PropTypes.bool.isRequired,
    pax2busDistributionEnabled: PropTypes.bool.isRequired,
};

TimelineToolbar.defaultProps = {
    rideId: null,
    rideUuid: '',
    rideIsDelayProcessEnabled: false,
    withBoosterBuses: false,
    pax2busDistributionEnabled: false,
};
