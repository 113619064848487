import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api/Client';

/**
 * @TODO create reuseable shared component
 */
export default function withNotificationsLogApi(Component) {
    return class WithNotificationsLogApi extends React.Component {
        static propTypes = {
            match: PropTypes.object,
            rideUuid: PropTypes.string.isRequired,
        };

        static defaultProps = {
            match: undefined,
        };

        state = {
            notificationsLog: [],
            loading: true,
        };

        componentDidMount() {
            this.fetchNotificationsLog();
        }

        componentWillUnmount() {
            api.cancel(this.requestUrl);
        }

        get requestUrl() {
            const { match, rideUuid } = this.props;
            // provide compatibility with route match params
            const id = (match && match.params.rideId) || rideUuid;

            return `/ride/v2/${id}/notifications-log`;
        }

        fetchNotificationsLog() {
            this.setState({ loading: true }, () => {
                api.get(this.requestUrl)
                    .then((response) =>
                        this.setState({
                            notificationsLog: response,
                            loading: false,
                        })
                    )
                    .catch((error) => {
                        if (!api.isCancel(error)) {
                            this.setState({ loading: false });
                        }
                    });
            });
        }
        render() {
            const { notificationsLog, loading } = this.state;
            if (notificationsLog.length > 0) {
                return (
                    <Component
                        {...this.props}
                        notificationsLog={notificationsLog}
                    />
                );
            } else if (loading) {
                return 'Loading...';
            }
            return 'No notifications';
        }
    };
}
