import React from 'react';
import PropTypes from 'prop-types';
import api from '../../../api/Client';

export default function withLockSeats(Component) {
    return class WithLockSeats extends React.Component {
        static propTypes = {
            rideUuid: PropTypes.string.isRequired,
            segmentId: PropTypes.string.isRequired,
            allSegments: PropTypes.array.isRequired,
            // callback that triggers to close form
            onClose: PropTypes.func.isRequired,
            // update Timeline with new data
            fetchTimeline: PropTypes.func.isRequired,
            notify: PropTypes.func,
        };

        static defaultProps = {
            notify: () => {},
        };

        state = {
            isProcessing: false,
            error: '',
        };

        /**
         * @TODO move it to the separate API enhancer
         * submit lock seats form
         * @func submit
         * @param {object} amount - Object with amount of locked seats and slots
         * @param {number} amount.rideUuid - Ride UUID for a seat lock
         * @param {number} amount.seatsLocked - Locked seats amount set
         * @param {number} amount.bikeSlotsLocked - Locked slots amount set
         * @param {number} amount.wheelchairsLocked - Locked wheelchairs amount set
         * @param {number} amount.prevSeatsLocked - Previous locked slots amount
         * @param {number} amount.prevBikeSlotsLocked - Previous locked slots amount
         * @param {number} amount.prevWheelchairsLocked - Previous locked wheelchairs amount
         * @param {number} amount.fromSegment - Segment ID to lock
         * @param {number} amount.toSegment - Segment ID to lock to
         * @param {number} amount.allToNextSegments - Whether or not to apply to next segments
         * @return {function} - function that perform request and manage state
         */
        submit = (amount) => () => {
            const { segmentId, allSegments, onClose, fetchTimeline, notify } =
                this.props;
            const rideUuid = amount.rideUuid || this.props.rideUuid;

            const data = {
                segment_id: [],
                seats_locked: {},
                bike_slots_locked: {},
                wheelchairs_locked: {},
            };

            const segmentsIDs = [];
            const lastSegmentId =
                allSegments[allSegments.length - 1] &&
                allSegments[allSegments.length - 1].id;

            let segmentStart = amount.fromSegment || segmentId;
            let segmentEnd = amount.allToNextSegments
                ? lastSegmentId
                : amount.toSegment || segmentStart;

            // set data with the initial opr the only one segment
            data.segment_id.push(segmentStart);
            data.seats_locked[segmentStart] =
                amount.seatsLocked - amount.prevSeatsLocked;
            data.bike_slots_locked[segmentStart] =
                amount.bikeSlotsLocked - amount.prevBikeSlotsLocked;
            data.wheelchairs_locked[segmentStart] =
                amount.wheelchairsLocked - amount.prevWheelchairsLocked;
            segmentsIDs.push(segmentStart);

            // fill the request data with custom amount of segments if they are passed
            if (segmentEnd) {
                let segments = [...allSegments];

                let spliceFromIdx = segments.findIndex(
                    (segment) => segment.id === segmentStart
                );
                let spliceToIdx =
                    segments.findIndex((segment) => segment.id === segmentEnd) +
                    1;

                const selectedSegments = segments.splice(
                    spliceFromIdx,
                    spliceToIdx - spliceFromIdx
                );

                selectedSegments.forEach((segment) => {
                    if (!data.segment_id.includes(segment.id)) {
                        data.segment_id.push(segment.id);
                    }
                    data.seats_locked[segment.id] =
                        amount.seatsLocked - segment.seats_locked;
                    data.bike_slots_locked[segment.id] =
                        amount.bikeSlotsLocked - segment.bike_slots_locked;
                    data.wheelchairs_locked[segment.id] =
                        amount.wheelchairsLocked - segment.wheelchairs_locked;

                    /**
                     * Validate resulting data set
                     */
                    if (
                        [
                            data.seats_locked[segment.id],
                            data.bike_slots_locked[segment.id],
                            data.wheelchairs_locked[segment.id],
                        ].some((i) => Number(i) >= 10000 || Number(i) <= -10000)
                    ) {
                        notify({
                            type: 'danger',
                            message: 'Data to lock capacity is corrupted',
                        });
                        throw new Error(
                            `Capacity lock data corruption. rideUUid: ${rideUuid}; segment: ${
                                segment.id
                            }; seats_locked: ${
                                data.seats_locked[segment.id]
                            }; bike_slots_locked: ${
                                data.bike_slots_locked[segment.id]
                            }; wheelchairs_locked: ${
                                data.wheelchairs_locked[segment.id]
                            }`
                        );
                    }

                    if (!segmentsIDs.includes(segment.id)) {
                        segmentsIDs.push(segment.id);
                    }
                });
            }

            const messageSegments = segmentEnd
                ? segmentsIDs.join(', ')
                : segmentStart;
            const message = `Seats or slots for segment ${messageSegments} was locked`;

            const allParam = amount.allToNextSegments ? '?all=true' : '';

            this.setState({ isProcessing: true });

            api.post(`/v2/ride/${rideUuid}/seat-lock${allParam}`, data)
                .then(() => {
                    this.setState({ isProcessing: false, error: '' }, () => {
                        fetchTimeline();
                        notify({
                            type: 'success',
                            message,
                        });
                        onClose();
                    });
                })
                .catch((e) => {
                    if (!api.isCancel(e)) {
                        this.setState({
                            isProcessing: false,
                            error: e.errors && e.errors[0],
                        });
                        notify({
                            type: 'danger',
                            message: (e.errors && e.errors[0]) || e.toString(),
                        });
                    }
                });
        };

        render() {
            const { isProcessing, error } = this.state;
            return (
                <Component
                    {...this.props}
                    lockSeats={this.submit}
                    isProcessing={isProcessing}
                    error={error}
                />
            );
        }
    };
}
