import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Switcher from '../UI/Switcher';
import { TranslateContext } from '../System/Translations';

export default function AutoModeTrigger(props) {
    const { enabled, inProcess, setAutomaticMode } = props;
    const translate = useContext(TranslateContext);
    return (
        <Switcher
            isOn={enabled}
            inProcess={inProcess}
            onChange={setAutomaticMode}
            legend={translate('auto-mode-trigger.label')}
        />
    );
}

AutoModeTrigger.propTypes = {
    // automatic mode state
    enabled: PropTypes.bool,
    setAutomaticMode: PropTypes.func.isRequired,
    inProcess: PropTypes.bool,
};

AutoModeTrigger.defaultProps = {
    enabled: false,
    inProcess: false,
};
