import React from 'react';

import api from '../../../api/Client';

export default function withActionsLogApi(Component) {
    return class WithActionsLogApi extends React.Component {
        static defaultProps = {
            notify: () => {},
        };

        state = {
            actionsLog: [],
            loading: true,
        };

        componentDidMount() {
            this.fetchActionsLog();
        }

        componentWillUnmount() {
            api.cancel('get-action-log');
        }

        fetchActionsLog() {
            const { match, rideUuid, notify } = this.props;
            // provide compatibility with route match params
            const id = (match && match.params.rideId) || rideUuid;

            this.setState({ loading: true }, () => {
                api.get(`/rideviewer/ride/v2/${id}/action-log`, {
                    cancelTokenId: 'get-action-log',
                })
                    .then((actions) => {
                        this.setState({
                            actionsLog: actions,
                            loading: false,
                        });
                    })
                    .catch((error) => {
                        if (!api.isCancel(error)) {
                            notify({
                                type: 'danger',
                                message: `[Action log]: ${error}`,
                            });
                        }
                    });
            });
        }

        render() {
            const { actionsLog, loading } = this.state;
            if (actionsLog.length > 0) {
                return <Component {...this.props} actionsLog={actionsLog} />;
            }
            if (loading) {
                return 'Loading...';
            }
            return 'No actions';
        }
    };
}
